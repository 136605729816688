/* whitelabel date picker themes in heartBeat module */
.whiteLabel > span {
    border: green !important;
    background-color: green !important;
}
.whiteLabelLeftBarItems > a{
    color: green !important;
}
.whiteLabelRightBarButton > button{
    background-color: green !important;
}